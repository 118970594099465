import { Trail } from "@react-three/drei";
import React, {useRef} from "react";
import { useFrame } from "@react-three/fiber";


export default function Rocket(props)
{
    const ref = useRef();
    let time;

    useFrame((state) =>
    {
        time = state.clock.getElapsedTime() * .5
        ref.current.position.set(100 * Math.sin(time) * 4, -600 + (-300 * Math.atan(time)) * Math.cos(time / 2) * 2, -1500 + (-1000 * Math.sin(time)))
    })

    return(
        <Trail width={25} length={40} color={"red"} attenuation={(t) => t * t}>
            <mesh ref = {ref} scale = {1}>
                <sphereGeometry args = {[1]}/>
                <meshPhongMaterial color = "red" toneMapped = {false}/>
            </mesh>
        </Trail>
    )
}