import React, { useEffect, useRef, useState, useMemo } from "react";
import { Object3D } from "three";
import {useFrame} from '@react-three/fiber'
import { useScroll } from "@react-three/drei";

//xSpread, ySpread, xScale, zScale, distanceBetweenZ, distanceBetweenX
export default function CityInstanced(props)
{
    const scroll = useScroll();
    const ref = useRef()
    const dummy = useMemo(() => new Object3D(), []);

    const positions = useMemo(() =>
    {
        let map = 
        {
            positions: [],
            scales: []
        };

        let xPos = 0;
        let zPos = 0;
        let yScale;

        for(let i = 0; i < props.xSpread; i++)
        {
            for(let x = 0; x < props.zSpread; x++)
            {
                yScale = Math.random() * 200;

                map.positions.push(xPos);
                map.positions.push(yScale / 2);
                map.positions.push(zPos);

                map.scales.push(yScale);

                zPos -= props.zScale + props.distanceBetweenZ;
            }

            zPos = 0;
            xPos += props.distanceBetweenX;
        }

        return map;
    }, [props.total])

    useFrame((_, delta) =>
    {
        if(scroll.offset > .9)
        {
            if(ref.current.position.y < 0)
            {
                ref.current.position.y += delta * 20;
            }
        }
        else
        {
            if(ref.current.position.y > -200)
            {
                ref.current.position.y -= delta * 270;
            }
        }
    })


    useEffect(() =>
    {
        let count = 0;

        for(let i = 0; i < positions.positions.length;)
        {
            dummy.position.set(positions.positions[ i ], positions.positions[ i + 1 ], positions.positions[ i + 2 ]);
            dummy.scale.set(props.xScale, positions.scales[count], props.zScale);
            dummy.updateMatrix();
            ref.current.setMatrixAt(count, dummy.matrix);

            count += 1;
            i += 3;
        }
        ref.current.instanceMatrix.needsUpdate = true;
    }, [positions.positions, positions.scales, props.xScale, props.zScale])

    let startX = props.xSpread * props.xScale * -1;
    startX *= .5;


    return(
        <>
            <group position = {[-1900,-1600,0]}>
                <pointLight position = {[1000, -400, 0]} distance = {2000}/>
                <pointLight position = {[3000, -400, 0]} distance = {2000}/>
                <instancedMesh ref = {ref} args = {[null, null, positions.positions.length * .33]} position = {[0,-200, 0]} >
                    <boxGeometry args = {[1,1,1]} />
                    <meshPhongMaterial color = "grey" shininess={40}/>
                </instancedMesh>
            </group>
            <mesh position = {[0,-1600,-500]} scale = {3500}  rotation = {[-Math.PI * .5, 0, 0]}>
                <planeGeometry args = {[1, 1]} rotation = {[0, 0, 0]}/>
                <meshBasicMaterial color = "black"/>
            </mesh>
        </>
    )
}