import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import React from 'react'
import logo from '../assets/liftedlogo.png'
import CloudHandler from './Clouds'
import CameraManager from './CameraManager'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import Lightning from './Lightning'
import { BottomText, LandingOpener, MoonText } from '../components/Text/LandingText'
import { ScrollControls, Scroll } from '@react-three/drei'
import Moon from './Moon'
import RisingStarSystem from './RisingStarSystem'
import CityInstanced from './CityInstanced'
import Rocket from './Rocket'

export default function SceneManager(props)
{
    const PAGES = 4.6;

    return(
        <div style = {{zIndex: -100, background: "black"}}>
            <Canvas style = {{width: "100vw", height: "100vh"}}>
                <ScrollControls pages = {PAGES}>
                    <CloudHandler />
                    <Lightning />
                    <Lightning />
                    

                    <Scroll html pages = {PAGES}>
                        <LandingOpener />
                        <MoonText />
                        <BottomText />
                    </Scroll>

                    <Rocket />

                    <CityInstanced pages = {PAGES} xSpread = {80} zSpread = {20} xScale = {30} zScale = {30} distanceBetweenZ = {60} distanceBetweenX = {60}/>
                    <CameraManager pages = {PAGES}/>
                    <color attach={"background"} args = {["black"]}/>
                    <Moon />

                    <Scroll>
                        <RisingStarSystem total = {2500} pages = {PAGES}/>
                    </Scroll>

                </ScrollControls>
            </Canvas>
        </div>
    )
}