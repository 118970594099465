import React, { useEffect, useRef, useState, useMemo } from "react";
import { Object3D, Color } from "three";
import {useFrame} from '@react-three/fiber'
import { useScroll } from "@react-three/drei";

//total
export default function RisingStarSystem(props)
{
    const ref = useRef()
    const scroll = useScroll()
    const ColorMemo = useMemo(() => new Color(), []);

    const ColorArray = useMemo(() => 
    Float32Array.from(new Array(props.total)
    .fill()
    .flatMap((_, i) => ColorMemo.setHex(
        (Math.random() > 0.5) ? 0xafc9ff : (Math.random() > 0.45) ? 0x00FF00 : 0xfff4f3)
        .toArray()))
        ,[props.total, ColorMemo]
    )

    const dummy = useMemo(() => new Object3D(), []);
    const positions = useMemo(() =>
    {
        let map = [];
        let lim = props.total * 3;

        const XLIM = 4500;
        const YLIM = -700;
        const ZLIM = -4500;


        for(let i = 0; i < lim; i++)
        {
            let x = ((Math.random() > 0.5) ? 1 : -1) * (Math.random() * XLIM);
            let y = YLIM - (Math.random() * 3400);
            let z = ((Math.random() > 0.5) ? 1 : -1) *  (Math.random() * ZLIM);

            map.push(x);
            map.push(y);
            map.push(z);
        }

        return map;
    }, [props.total])

    useEffect(() =>
    {
        let count = 0;

        for(let i = 0; i < positions.length;)
        {
            dummy.position.set(positions[ i ], positions[ i + 1 ], positions[ i + 2 ]);
            dummy.updateMatrix();
            ref.current.setMatrixAt(count, dummy.matrix);

            count += 1;
            i += 3;
        }
        ref.current.instanceMatrix.needsUpdate = true;
    }, [])

    useFrame((_, delta) =>
    {   
        ref.current.rotation.y += delta * .05;

        if(scroll.offset < .25 && ref.current.position.y > -1000)
        {
            ref.current.position.y -= 3;
        }
        else if(scroll.offset > .25 && ref.current.position.y < 1500)
        {
            if(ref.current.visible == false)
            {
                ref.current.visible = true;
            }
            ref.current.position.y += 3;
        }
        else if(scroll.offset < .25 && ref.current.visible)
        {
            ref.current.visible = false;
        }
    })

    return(
        <instancedMesh ref = {ref} args = {[null, null, props.total]} position = {[0,-1000,0]}>
            <sphereGeometry args = {[1, 4, 8]}>
                <instancedBufferAttribute attach={"attributes-color"} args = {[ColorArray, 3]}/>
            </sphereGeometry>
            <meshBasicMaterial color = "white" vertexColors toneMapped = {false}/>

        </instancedMesh>
    )
}