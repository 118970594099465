import { useThree } from "@react-three/fiber";
import React, {useRef} from "react";
import { useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Vector3 } from "three";

export default function CameraManager()
{
    const {camera} = useThree();

    camera.far = 12000;
    camera.updateProjectionMatrix();

    const initialPosition = new Vector3(0,0,0);
    const endPosition = new Vector3(0, -945, 0);

    const scrollBenchmark = useRef(0)
    
    const scroll = useScroll()

    useFrame(() =>
    {
        if(scroll.offset === scrollBenchmark.current) return;
        camera.position.lerpVectors(initialPosition, endPosition, scroll.offset);
        scrollBenchmark.current = scroll.offset;
    })
    
    return(
        <>
            <pointLight intensity = {5.4} color = "grey" position = {[0, 0, -500]} distance = {1100}/>
        </>
    )
}