import * as THREE from 'three'
import React, {useRef, useState} from 'react'
import logo from '../assets/liftedlogo.png'
import logo2 from '../assets/liftedLogo2.png'
import { useFrame } from '@react-three/fiber'


export default function SpinLogo(props)
{
    const ref = useRef(0)
    const [dir, setDir] = useState(0);
    const [up, setUp] =  useState(0)

    var map = new THREE.TextureLoader().load(logo2)
    map.flipY = true;
    var mat = new THREE.MeshBasicMaterial({ transparent: true, side: THREE.DoubleSide})
    mat.map = map;
    

    var map2 = new THREE.TextureLoader().load(logo)
    map2.flipY = true;
    var mat2 = new THREE.MeshBasicMaterial({ transparent: true, side: THREE.DoubleSide})
    mat2.map = map2;


    var blank = new THREE.MeshBasicMaterial({opacity: 0, transparent: true, color: "white"})

    useFrame((_, delta) =>
    {
        //lateral rotation
        if(dir == 0)
        {
            ref.current.rotation.y += delta * .15;
        }
        else
        {
            ref.current.rotation.y -= delta * .15;
        }
        
        //vertical movement

        if(up == 0)
        {
            ref.current.position.y += delta * 0.15;
        }
        else
        {
            ref.current.position.y -= delta * 0.25;
        }

        if(ref.current.position.y > 0.2)
        {
            ref.current.position.y = 0.2;
            setUp(1);
        }
        else if(ref.current.position.y < -0.2)
        {
            ref.current.position.y = -0.2;
            setUp(0);
        }

        if(ref.current.rotation.y > 0.2)
        {
            ref.current.rotation.y = 0.2;
            setDir(1);
        }
        else if(ref.current.rotation.y < -0.2)
        {
            ref.current.rotation.y = -0.2;
            setDir(0);
        }
    })

    return(
        <>
            <group ref = {ref} position={[0,0,-10]}>
                <mesh  material = {mat2}>
                    <planeGeometry args = {[25,25]}/>
                </mesh>

            </group>
        </>
    )
}