import { TextureLoader, NearestFilter, RepeatWrapping, sRGBEncoding } from "three";
import React, { useRef } from "react";
import spark from '../assets/spark_05.png';
import { useFrame } from "@react-three/fiber";

export default function Lightning(props)
{
    const ref = useRef()
    const elapsedBenchmark = useRef(0);
    const visibleCutoff = useRef(0);
    const visibleTime = useRef(0);

    const loaded = new TextureLoader().load(spark);
    loaded.minFilter = NearestFilter;
    loaded.magFilter = NearestFilter;
    loaded.wrapS = RepeatWrapping;
    loaded.wrapT = RepeatWrapping;
    loaded.encoding = sRGBEncoding;

    //lightning strikes withhin 5 seconds
    elapsedBenchmark.current = (Math.random() * 2) + 1;
    visibleTime.current = 1 + Math.random() * 1.5;
    visibleCutoff.current = elapsedBenchmark.current + visibleTime.current;

    useFrame((state) =>
    {
        if(state.clock.getElapsedTime() < elapsedBenchmark.current && ref.current.visible === true)
        {
            ref.current.visible = false;
        }
        else if(visibleCutoff.current < state.clock.getElapsedTime())
        {
            elapsedBenchmark.current = (Math.random() * 2) + state.clock.getElapsedTime() + 1;
            visibleTime.current = 0.25 + Math.random() * 1.5;
            visibleCutoff.current = elapsedBenchmark.current + visibleTime.current;

            ref.current.position.x = ((Math.random() > .5) ? 1:-1) * 500 * Math.random();
            ref.current.position.z = -1500 + Math.random() * -8000;

            ref.current.intensity = Math.random() * 5;
            ref.current.distance = 1000 * Math.random();

        }
        else if(state.clock.getElapsedTime() > elapsedBenchmark.current && ref.current.visible === false)
        {
            ref.current.visible = true;

            if(ref.current.distance < 1000)
            {
                ref.current.distance += Math.random();
            }
        }
        else if(ref.current.visible === true)
        {
            ref.current.visible = false;

            if(ref.current.distance < 1000)
            {
                ref.current.distance += Math.random();
            }
        }

    })



    return(
            <pointLight position = {[((Math.random() > .5) ? 1:-1) * 1500 * Math.random(),150,-1000 + Math.random() * -12000]} distance = {100} intensity = {60} color = "#7DF9FF" ref = {ref}/>
    )
}