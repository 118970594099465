import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import '../css/Fonts.css'
import '../css/glowButton.css'
import '../css/Landing.css'
import '../css/Phase2.css'
import '../css/Phase3.css'
import '../css/Cards.css'
import vsample from '../../assets/vertsample.mp4'
import vsample2 from '../../assets/vertsample2.mp4'
import vsample3 from '../../assets/vertsample3.mp4'
import vsample4 from '../../assets/vertsample3.mp4'
import gsap from "gsap";
import pfp from '../../assets/pfp.png'

import Tilt from 'react-parallax-tilt'

import gh from '../../assets/ghub.png'
import linkedin from '../../assets/lilogo.png'

const Col2 = styled.div`
    display: flex;
    flex-direction:column;
    align-items: left;

`

export function LandingOpener()
{
    const [anim, setAnim] = useState(null)

    const r1 = useRef()
    const r2 = useRef()

    useEffect(() =>
    {
        if(anim) return;
        if(!r1.current) return;
        
        const tl = gsap.timeline({paused: true});
        tl.to(r1.current, {opacity: 1, y: 0, duration: 2, delay: 1})
        .to(r2.current, {opacity: 1, y: 0, duration: 2}, "-=1")

        setAnim(tl);
    }, [])

    useEffect(() =>
    {

        if(!anim) return;
        anim.play();

    }, [anim])

    return(
        <>
            <div 
            className = "LandingContainer">
                <h1 className = "BigShoulders Header" id = "H" ref = {r1}>
                    
                    Let's Realize Your Vision

                </h1>

                <p className = "notosans Subtitle" id = "S" ref = {r2}>
                    
                    Delivering software, web, and blockchain solutions 
                    with your interests first. 
                    

                </p>
            </div>
        </>
    )
}

export function MoonText(props)
{


    return(
        <div className = "FullContainer">
            <div className = "rajdhani Text2Container">
                <h2 className = "Text2 oswald">
                    
                    Who is Cameron Warnick?       

                    </h2>

                <h2 className = "Text2">
                    
                    I am a software developer with applied experience building 
                    innovative technology from the ground-up. I'm ready to tackle your 
                    unique challenges, whether you need 
                    an animation-driven website, highly-optimized smart contracts, 
                    custom software, deep learning solutions, or anything in between.  

                </h2>
                <VideoGallery />
            </div>
        </div>
    )
}

const VideoGallery = () => {

    const videos = [
        { src: vsample, type: "video/mp4", 
        description: 
        <span>
            This project aimed to make Web3 more accessible, allowing users to build passive token
            rewards by completing business-supplied tasks.  I built a Web3-integrated frontend, a 
            novel Solidity-based DeFi ecosystem leveraging custom ERC20 and ERC721 applications, 
            wrote extensive documentation, and pitched VertigoDAO to several launchpads and incubators. 
            &nbsp;
            
            <a style = {{color: "white"}} href="https://drive.google.com/file/d/1VscKC7Luowxel8mS4N6_uMJgC7DZOuoH/view" rel="noopener noreferrer" target="_blank" >Read the whitepaper!</a>
        </span>, 
        title: "VertigoDAO"
        },
        { src: vsample2,
            type: "video/mp4", 
            description: 
            <span>

                The highlight of this page is the infinite city environment I built using 
                @react-three/fiber.  Buildings are randomly generated using an optimized procedural algorithm, 
                with each bearing varying traits and positions.  Notably, all building vertices are instanced into indvidual class-specific 
                meshes.  Thanks to these and many other optimizations, this detailed city scene is easily 
                compatible with modern devices.  Credit to my friend, the talented graphic designer 
                <a href = "https://www.linkedin.com/in/kim-hoang-466134172/" 
                style = {{ color: "white", fontWeight: "bold"}} rel="noopener noreferrer" target = "_blank"> Kim Hoang</a>&nbsp; for designing the 
                building textures and many of the graphical elements present on the VertigoDAO website. If you need 
                graphic design work, Kim is an excellent choice.
            </span>, 
            title: "VertigoDAO Litepaper"},
            { src: vsample3,
                type: "video/mp4", 
                description: 
                <span>
    
                    This was the first smart contract system I built using almost pure assembly.  
                    I am a huge proponent of Solidity Assembly: well-optimized AND COMMENTED 
                    assembly code, when used in the right places, can offer significant cost savings.
                    Furthermore, understanding low-level operations 
                    opens the door to a significant amount of additional capabilities, such as direct 
                    storage space management, which can be useful for Layer-1 scalability applications.   &nbsp;
                    <a style = {{color: "white"}} href="https://github.com/wisecameron/AerialUber/blob/main/AerialUber.sol" rel="noopener noreferrer" target="_blank">Read the code!</a>
                </span>, title: "AerialUber (Solidity Assembly)"},
                { src: vsample4,
                    type: "video/mp4", 
                    description: 
                    <span>
        
                       This is the precursor to my most recent, but currently private project, (which is more-or-less a much better 
                       version of the same thing).  Layer-1 smart contract scalability and optimization has been a huge interest of mine for a 
                       while, and this was my first attempt at solving the puzzle.  Ultimately, this system provides some interesting 
                       benefits, including batch storage and retrieval and extendable storage structures.  This process helped me to 
                       learn a substantial amount about little-explored elements of the Solidity toolset, and was my first 
                       deep dive into assembly.
                       
                        &nbsp;<a style = {{color: "white"}} href="https://github.com/wisecameron/ExpressStorageSystem/blob/main/contracts/StorageSystem.sol" target="_blank" rel="noopener noreferrer">Read the code!</a>
                    </span>, title: "Express Storage System (Solidity Assembly)"}
    ]

    const videoRef = useRef()

    const [src, setSrc] = useState(vsample);
    const [type, setType] = useState("video/mp4")
    const [description, setDesc] = useState("")
    const [title, setTitle] = useState("")

    const [currentIndex, setCurrentIndex] = useState(0);

    const NextVideo = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    useEffect(() =>
    {
        setDesc(videos[currentIndex].description);
        setSrc(videos[currentIndex].src);
        setTitle(videos[currentIndex].title)

        // Tell the video element to load the new source
        if(videoRef.current) {
            videoRef.current.load();
            videoRef.current.play(); // Optionally play the video after loading the new source
        }

    }, [currentIndex])


    return (
        <>
            <div className = "VidMargin">
                <video 
                ref = {videoRef}
                className="Vid" autoPlay loop muted playsInline >
                    <source src={src} type={type} />
                    Your browser does not support the video tag.
                </video>
                <Col2>
                    <p className = "DescTitle"><b>{title}</b></p>
                    <p className="Desc">{description}</p>
                </Col2>
            </div>
            <p style = {{color: "white", marginTop: "0vh"}}><i>➼ &nbsp;Hover to enlarge</i></p>
            <button className = "glowingButton" style = {{cursor: "pointer", fontSize: "2vmax", width: "40%"}} onClick={NextVideo}>Next Item</button>
        </>
    );
};

export const Glass = ({link, el}) =>
{
    const [color, setColor] = useState("grey")
    const [hovered, setHovered] = useState(false)

    useEffect(() =>
    {
        if(hovered) 
        {
            setColor("white")
        }   
        else
        {
            setColor("grey")
        }
    }, [hovered])

    return(
        <div 
        className= "glass ImgStyle"
        id = {el}
        style = {{background: color}}
        onPointerOver={() => {setHovered(true)}}
        onPointerOut={() => {setHovered(false)}}
        onClick={() => {window.open(link, "_blank", "noopener,noreferrer")}}>
            <img src = {(el === "el1") ? pfp : (el === "el2") ? gh : linkedin} style = {{width: "5vw"}}/>
        </div>
    )
}

export function BottomText(props)
{
    useEffect(() =>
    {
        let t = gsap.timeline({repeat: -1, yoyo: true})
        .to("#el1", {height: "5vh"})
        .to("#el2", {height: "5vh"})
        .to("#el3", {height: "5vh"});

    }, [])


    return(
        <div className="SectionContainer">
            <h2 className = "BigShoulders" style = {{color: 'white', fontSize: "4.5vmax"}}>
                Let's Connect!
            </h2>
            <div className="Row">
                <div className = "CardContainer">
                    <Tilt className="Tilt">
                        <Glass el = "el1" link = "https://drive.google.com/file/d/1TMsG_JjUZahQIfLRsoK92gHWw94I2oeT/view?usp=sharing">
                        </Glass>
                    </Tilt>
                    <h2 className="Text anta">Resume</h2>
                </div>
                <div className = "CardContainer">
                    <Tilt className="Tilt">
                        <Glass link = "https://github.com/wisecameron" el = "el2">
                        </Glass>
                    </Tilt>
                    <h2 className="Text anta">Github</h2>
                </div>
                <div className = "CardContainer">
                    <Tilt className="Tilt">
                        <Glass el = "el3" link = "https://www.linkedin.com/in/cameron-warnick-64a25222a/">
                        </Glass>
                    </Tilt>
                    <h2 className="Text anta">Linkedin</h2>
                </div>
            </div>
        </div>
    )

/*

*/
}