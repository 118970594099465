import { Object3D, TextureLoader } from "three";
import { useRef, useMemo, useEffect } from "react";
import {useFrame, useThree} from '@react-three/fiber';
import cloudImg from '../assets/cloud.png';
import { MeshLambertMaterial, NearestFilter, RepeatWrapping, sRGBEncoding } from "three";
import React from "react";
export const CloudMaterial = (Loader) =>
{
    const loaded = Loader.load(cloudImg);
    loaded.minFilter = NearestFilter;
    loaded.magFilter = NearestFilter;
    loaded.wrapS = RepeatWrapping;
    loaded.wrapT = RepeatWrapping;
    loaded.encoding = sRGBEncoding;

    const mat = new MeshLambertMaterial({ map: loaded, transparent: true, fog: false});

    mat.alphaMap = loaded;
    mat.depthWrite = false;
    //mat.alphaTest = 0.5;
    return mat;
}


/*
    Depth, width
*/
export function CloudSystemInstanced(props)
{
    const Loader = new TextureLoader();
    const material = CloudMaterial(Loader);
    const dummy = useMemo(() => {return new Object3D(0,0,0)}, []);
    const ref = useRef();

    const zFin = useRef(-150);
    const zLim = useRef(props.zLim);
    const {camera} = useThree()

    let yPosLow = 0;
    const positions = useMemo(() =>
    {
        let map = []
        let x = -1500;
        let y = 0;
        let z = zFin.current;

        for(let i = 0; i < props.depth; i++)
        {
            z = zFin.current;
            for(let j = 0; j < props.width; j++)
            {
                x += 150 + Math.random() * 250
                y = yPosLow + Math.random() * 20;
                z += (Math.random() > 0.5) ? -1 : 1 * 120.5 * Math.random();
                map.push(x);
                map.push(y);
                map.push(z);    
            }
            zFin.current -= props.zStep;
            x = -1500;
        }

        return map;

    }, [ props.width, props.depth ])

    useFrame((state, delta) =>
    {
        if(!props.static && document.hasFocus())
        {
            ref.current.position.y += delta * 20;

            if(ref.current.position.y > 600)
            {
                ref.current.position.y = -50;
            }
            //ref.current.position.z -= 0.5;
            //console.log(ref.current.position.y)

        }

    })

    useEffect(() =>
    {
        let c = 0;
        let totalPositions = 3 * (props.depth * props.width);

        for(let i = 0; i < totalPositions; i+=3)
        {
            dummy.position.set(positions[i], positions[i + 1], positions[i + 2]);
            dummy.rotation.set(1.3,0,0)
            dummy.updateMatrix();
            ref.current.setMatrixAt(c, dummy.matrix);
            c++;
        }
        ref.current.instanceMatrix.needsUpdate = true;
    }, [])

    return(
        <instancedMesh
        rotation = {[0,0,0]}
        ref = {ref}
        {...props}
        args = {[null, null, positions.length / 3]}
        material = {material}
        scale = {1}>
            <planeGeometry args = {[500,500]} />
        </instancedMesh>
    )
}

export default function CloudHandler()
{
    const zStep = 400;
    const zLim = -2 * zStep;

    const depth = 25;
    const width = 25;


    
    return(
        <>
            <CloudSystemInstanced width = {width} depth = {depth} position = {[-100,0,-400]} zLim = {zLim * depth} zStep = {zStep} static = {false}/>
            <CloudSystemInstanced width = {width} depth = {depth} position = {[-100,100,-400]} zLim = {-zStep * depth} zStep = {zStep} static = {false}/>
            <CloudSystemInstanced width = {width} depth = {depth} position = {[-100,200,-400]} zLim = {zLim * depth} zStep = {zStep} static = {false}/>
            <CloudSystemInstanced width = {width} depth = {depth} position = {[-100,300,-400]} zLim = {-zStep * depth} zStep = {zStep} static = {false}/>
            <CloudSystemInstanced width = {width} depth = {depth} position = {[-100,400,-400]} zLim = {zLim * depth} zStep = {zStep} static = {false}/>
            <CloudSystemInstanced width = {width} depth = {depth} position = {[-100,500,-400]} zLim = {zLim * depth} zStep = {zStep} static = {false}/>
        </>
    )
}