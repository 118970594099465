import React from 'react'
import Header from './components/Header';
import SceneManager from './LandingPageScene/SceneManager';

function App() {
  return (
    <div >
      <Header />
      <SceneManager />
    </div>
  );
}

export default App;
