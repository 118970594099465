import { TextureLoader, sRGBEncoding } from "three";
import { useLoader } from "@react-three/fiber";
import React, { useRef, useState } from "react";
import { MeshWobbleMaterial } from "@react-three/drei";
import {useFrame} from '@react-three/fiber'
import { useScroll } from "@react-three/drei";

export default function Moon(props)
{
    const ref = useRef(0);

    const MoonTexture = useLoader(TextureLoader, 'moon.jpg');
    MoonTexture.encoding = sRGBEncoding;

    const scroll = useScroll()

    useFrame((_, delta) =>
    {
        if(scroll.offset > .5)
        {
            if(ref.current.position.x < 800)
            {
                ref.current.position.x += delta * 75;
            }
            if(ref.current.position.y > -560)
            {
                ref.current.position.y -= delta * 15;
            }
            if(ref.current.position.z > -1000)
            {
                ref.current.position.z -= delta * 50;
            }
        }
        else
        {
            if(ref.current.position.y < -450)
            {
                ref.current.position.y += delta * 30;
            }
            if(ref.current.position.x > 0)
            {
                ref.current.position.x -= delta * 150;
            }
            if(ref.current.position.z < -500)
            {
                ref.current.position.z += delta * 100;
            }
        }
        
    })

    return(
        <group position = {[0, -450, -500]} ref = {ref}>
            <mesh >
                <sphereGeometry  args = {[245]}/>
                <MeshWobbleMaterial factor = {0.35} speed = {0.5} shininess = {50} map = {MoonTexture} emissive = "white" emissiveIntensity={0.005}/>
            </mesh>

        </group>

    )
}