import React from 'react'
import styled from 'styled-components'
import { Canvas } from '@react-three/fiber'
import SpinLogo from '../LandingPageScene/SpinLogo'
import './css/Fonts.css'
import telegramLogo from '../assets/telegram.png'
import igLogo from '../assets/iglogo.webp';

const HeaderContainer = styled.div`
    width: 100vw;
    height: 10vh;
    background: none;
    position: fixed;
    z-index: 1000;
`

const TagLine = styled.h1`
    color: white;
    font-size: 1.6vmax;
    cursor: pointer;
    
    &:hover
    {
        color: grey;
    }
`

const LogoImg = styled.img`
    height: 2.5vmax;
    margin-top: 1.1vmax;
    cursor: pointer;

    &:hover
    {
        height: 3vmax;
        margin-top: 0.75vmax;

    }

`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`




export default function Header(props)
{

    return(
        <>
            <HeaderContainer {...props} style = {{zIndex: 100}}>
                    <div style = {{width: "5vmax", height: "5vmax", marginLeft: "2vw", marginTop: "2vh"}}>
                        <Canvas style = {{ marginTop: "-0.2vmax"}}>
                            <SpinLogo />
                        </Canvas>
                    </div>

            </HeaderContainer>
        </>
    )

}